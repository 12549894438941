.vision-card {
  background-color: var(--color-blue);
  margin-top: -15px;
  padding-left: 0.5em;
  padding-top: 1em;
  padding-bottom: 5em;
}

.vision-section {
  margin-top: -30px;
}

.vision-section h6 {
  padding-top: 0.5em;
}

.vision-section p {
  padding-right: 5em;
}

.vision-image {
  margin-left: -40px;
}

@media (max-width: 1200px) {
  .vision-image img {
    height: 220px;
  }
}

@media (max-width: 991px) {
  .vision-card {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .svg-addition {
    display: none;
  }

  .vision-image {
    margin-left: 0;
  }
  .vision-image img {
    height: 330px;
  }
}

@media (max-width: 768px) {
  .vision-card {
    border-radius: 10%;
  }
}

@media (max-width: 768px) {
  .vision-card {
    margin-top: 50px;
  }
}

@media (max-width: 500px) {
  .vision-section p {
    padding-right: 0;
  }
  .vision-image img {
    height: 220px;
  }
}

@media (max-width: 375px) {
  .vision-card p {
    padding-right: 20px;
  }
}
