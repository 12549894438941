* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-white: #f1f4f8;
  --color-black: #070707;
  --color-gray: #d3d3d3;
  --color-blue: #4472c4;
  --color-blue-secondary: #3b68a3;
}

body {
  overflow-x: hidden;
  background-color: var(--color-black);
}

.main-sections {
  background-color: var(--color-black);
}

.computer-aircraft-components {
  background-color: var(--color-black);
  margin-bottom: 100px;
}

.background-light {
  background-color: var(--color-white);
}

@media (max-width: 940px) {
  .about-card {
    clip-path: none;
    border-radius: 50px;
  }

  .mission-card {
    clip-path: none;
    border-radius: 50px;
  }

  .vision-card {
    clip-path: none;
    border-radius: 50px;
  }
}
