.credentials {
  max-width: 100%;
  padding-bottom: 110px;
}

.credentials a {
  text-decoration: none;
  color: var(--color-black);
  transition: all 0.3s;
}

.title {
  color: var(--color-blue);
}

.credentials a:hover {
  color: var(--color-blue);
}

.credentials {
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .license {
    margin-top: 20px;
  }
}
