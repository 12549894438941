.computer-aircraft-el-lists {
  background-color: var(--color-black);
}

.computer-aircraft-el-lists a {
  text-decoration: none;
}

.computer-aircraft-el-lists a:hover {
  text-decoration: underline;
  color: azure;
}

.computer-hardware-image img {
  width: 35em;
}

@media (max-width: 1400px) {
  .computer-hardware-image img {
    width: 100%;
  }
}
