.aircraft-lists a {
  text-decoration: none;
}

.aircraft-lists a:hover {
  text-decoration: underline;
  color: azure;
}

.aviation-parts-image img {
  width: 33em;
}

@media (max-width: 1400px) {
  .aviation-parts-image img {
    width: 100%;
  }
}
