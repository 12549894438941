.wavy-bottom {
  display: none;
}

@media (max-width: 991px) {
  .section-card p {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .section-card p {
    font-size: 15px;
  }
}
