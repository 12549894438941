.mission-card {
  clip-path: polygon(
    11% 0,
    80% 0,
    100% 20%,
    100% 100%,
    80% 100%,
    20% 100%,
    0 100%,
    0 0
  );
  background-color: var(--color-blue);
  height: 22em;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 1em;
}

.mission-section p {
  padding-right: 4.5em;
}

.mission-description {
  margin-left: -70px;
  margin-top: -60px;
}

@media (max-width: 1400px) {
  .mission-section img {
    height: 270px;
  }
}

@media (max-width: 1200px) {
  .mission-section p {
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .mission-description {
    margin-left: 0;
    margin-top: 20px;
  }

  .mission-card {
    clip-path: none;
    margin-bottom: 30px;
  }

  .mission-section img {
    height: 330px;
  }
}

@media (max-width: 768px) {
  .mission-card {
    border-radius: 10%;
  }
}

@media (max-width: 500px) {
  .mission-card {
    padding-bottom: 400px;
  }

  .mission-card p {
    padding-right: 5px;
  }
  .mission-section img {
    height: 270px;
  }
}

@media (max-width: 375px) {
  .mission-card p {
    padding-right: 20px;
  }
}
