.branding-title {
  padding-top: 90px;
}

@media (max-width: 1200px) {
  .branding-title {
    padding-top: 50px;
  }
}

@media (max-width: 991px) {
  .branding-section {
    margin-top: 30px;
  }
  .branding-title {
    padding-top: 25px;
  }
}
