.banner-addition {
  background-color: var(--color-blue);
  height: 6.25em;
}

.parallax-img {
  height: 100vh;
  width: 100vw;
}

.banner-header {
  padding-top: 32px;
}

.blue-banner {
  margin-left: 0;
  margin-right: 0;
  margin-top: 220px;
}

.home-banner-img {
  margin-top: 15px;
  display: block;
  background-size: cover;
  border: 2px solid var(--color-blue);
  border-radius: 10%;
  box-shadow: 0 12px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-banner-img:hover {
  transform: scale(
    1.1
  ); /* Adjust the scaling factor for the highlight effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 878px) {
  .banner-addition {
    height: 5.5em;
  }
}

@media (max-width: 768px) {
  .banner-addition {
    margin-top: 300px;
  }
}

@media (max-width: 550px) {
  .banner-header {
    padding-top: 17px;
  }
}
