.about-card {
  clip-path: polygon(
    13% 0,
    80% 0%,
    100% 20%,
    100% 100%,
    80% 100%,
    20% 100%,
    0% 80%,
    0 11%
  );
  background-color: var(--color-blue);
  height: 20em;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
}

.about-image img {
  width: 37.5em;
}

.about-card p {
  padding-right: 1.5em;
}

@media (max-width: 991px) {
  .about-image {
    padding-top: 20px;
  }

  .about-image img {
    width: auto;
  }

  .about-card {
    clip-path: none;
  }
}

@media only screen and (max-width: 991px) {
  .img-fluid {
    width: 0;
  }
}

@media (max-width: 768px) {
  .about-card {
    border-radius: 10%;
  }
}

@media (max-width: 500px) {
  .about-card {
    padding-bottom: 400px;
  }
  .about-card p {
    padding-right: 0;
  }
}

@media (max-width: 375px) {
  .about-card p {
    padding-right: 20px;
  }
}
