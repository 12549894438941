.trading-title {
  font-size: 70px;
}

.trading-title .first-part {
  color: var(--color-blue-secondary);
}

.trading-title .second-part {
  color: var(--color-black);
}

.trading-banner {
  background-color: var(--color-white);
}

.trading-banner img {
  width: 60%;
  margin-left: 12em;
}

@media (max-width: 1400px) {
  .trading-banner img {
    margin-left: 7em;
  }
}

@media (max-width: 500px) {
  .trading-banner img {
    margin-left: 5em;
  }
  .trading-title {
    text-align: center;
  }
}
