.footer {
  max-width: 100%;
  background-color: var(--color-blue);
}

.footer-container {
  height: 300px;
  border-radius: 60px;
}

.footer-logo img {
  width: 65%;
}

.footer-address p {
  font-size: 18px;
}

.second-part {
  color: var(--color-black);
}

@media (max-width: 768px) {
  .footer-title {
    text-align: start;
  }
  .footer-logo img {
    width: 50%;
  }
}
